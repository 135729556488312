.generic-bg {
  background: #667db6;
  background: -webkit-linear-gradient(
    to bottom,
    #667db6,
    #0082c8,
    #0082c8,
    #667db6
  );
  background: linear-gradient(to bottom, #667db6, #0082c8, #0082c8, #667db6);
}



.Blink {
  animation: blinker 1.5s cubic-bezier(.5, 0, 1, 1) infinite alternate;  
}
@keyframes blinker {  
  from { opacity: 1; }
  to { opacity: 0; }
}


.fade-out {
  animation: fadeOut linear 0.5s;
  -webkit-animation: fadeOut linear 0.5s;
  -moz-animation: fadeOut linear 0.5s;
  -o-animation: fadeOut linear 0.5s;
  -ms-animation: fadeOut linear 0.5s;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade-in {
  animation: fadeIn linear 0.6s;
  -webkit-animation: fadeIn linear 0.6s;
  -moz-animation: fadeIn linear 0.6s;
  -o-animation: fadeIn linear 0.6s;
  -ms-animation: fadeIn linear 0.6s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
