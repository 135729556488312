// Typography
:root {
  --primary-font: 'DefaultFont'; // Set a default font
}

//@import url('https://fonts.googleapis.com/css2?family=Anta:wght@300;400;600&family=Fuggles:wght@300;400;600&display=swap');

// Use the CSS variable for the font family

.bg{
  background-image:  var(--systemBG, url('https://firebasestorage.googleapis.com/v0/b/yoyoreact-dev.appspot.com/o/hosts%2Fyoyo.dev.reply.gift%2F0186646e-40b4-4095-b2d5-99be35b527cc.webp?alt=media&token=2886dd91-b42c-4ff7-ad02-44a600f2e906'));
  background-color:  var(--systemBGColor, 'transperent');
}

.header {
  background-color: var(--header-bg-color, #ffffffee);
}

body {
  font-family: var(--primary-font, 'Dosis'), sans-serif; // Fallback to 'Dosis' if the variable is not set
}

.panel {
  background-color: var(--panel-bg-color, #ffffff);
}


.heading {
  font-family: var(--heading-font, "Merriweather", serif);
  color: var(--heading-color, #2e2d2d); /* default color text-gray-700 */
  font-weight: var(--heading-font-weight, 700); /* default to light */
  @apply text-xl pt-4;
  @screen md {
    @apply text-2xl;
  }
  @screen xxl {
    @apply text-3xl;
  }
}

.body {
  font-family: var(--body-font, "Merriweather", serif);
  color: var(--body-color, #2e2d2d); /* default color text-gray-700 */
  font-weight: var(--body-font-weight, 700); /* default to light */
  @apply text-base pt-4;
  @screen md {
    @apply text-lg;
  }
}

.info {
  font-family: var(--info-font, "Merriweather", serif);
  color: var(--info-color, #2e2d2d); /* default color text-gray-700 */
  font-weight: var(--info-font-weight, 700); /* default to light */
  @apply text-sm;
  @screen md {
    @apply text-base;
  }
  @screen lg {
    @apply text-lg ;
  }
}

.overlay {
  font-family: var(--overlay-font, "roboto", serif);
  color: var(--overlay-color, #2e2d2d); /* default color text-gray-700 */
  font-weight: var(--overlay-font-weight, 400); 
  background-color: var(--overlay-fill, rgba(255,255,255,.9));
  border-color: var(--overlay-border-color, #000); 
  border-style: var(--overlay-border-style, 'solid');
  border-width: var(--overlay-border-width, '2px');
  box-shadow: var(--overlay-shadow, 0 25px 50px -12px rgb(0 0 0 / 0.25));
  @apply text-base text-center;
  @screen lg {
    @apply text-lg ;
  }
}

.prmy_btn {
  font-family: var(--prmy_btn-font, "Merriweather", serif);
  color: var(--prmy_btn-color, #2e2d2d); /* default color text-gray-700 */
  font-weight: var(--prmy_btn-font-weight, 700); /* default to light */
  background-color: var(--prmy_btn-fill,rgb(252, 204, 43)); 
  border-color: var(--prmy_btn-border-color, #000); 
  border-style: var(--prmy_btn-border-style, 'double');
  border-width: var(--prmy_btn-border-width, '5px');
  box-shadow: var(--prmy_btn-shadow, 0 0 #0000);
  @apply text-lg;
  @screen lg {
    @apply text-xl;
  }
}

.accent_btn {
  font-family: var(--accent_btn-font, "Merriweather", serif);
  color: var(--accent_btn-color, #2e2d2d); /* default color text-gray-700 */
  font-weight: var(--accent_btn-font-weight, 700); /* default to light */
  background-color: var(--accent_btn-fill, #a7aa0cde);
  border-color: var(--accent_btn-border-color, #9e5006); 
  border-style: var(--accent_btn-border-style, 'none');
  border-width: var(--accent_btn-border-width, '1px');
  box-shadow: var(--accent_btn-shadow, 0 25px 50px -12px rgb(0 0 0 / 0.25));
  @apply text-lg;
  @screen lg {
    @apply text-xl;
  }
}

.thrd_btn {
  font-family: var(--thrd_btn-font, "Merriweather", serif);
  color: var(--thrd_btn-color, #ffffff); /* default color text-gray-700 */
  font-weight: var(--thrd_btn-font-weight, 700); /* default to light */
  background-color: var(--thrd_btn-fill, #420669e8);
  border-color: var(--thrd_btn-border-color, #9e5006); 
  border-style: var(--thrd_btn-border-style, 'none');
  border-width: var(--thrd_btn-border-width, '1px');
  box-shadow: var(--thrd_btn-shadow, 0 25px 50px -12px rgb(0 0 0 / 0.25)); 
  @apply text-lg;
  @screen lg {
    @apply text-xl;
  }
}

/* Custom styles for different breakpoints */
.record-button-position {
  bottom: calc(30% - 16px);
  transform: translateY(50%);
  z-index: 30;
}

@media (min-width: 375px) { /* Tailwind's sm breakpoint */
  .xs\\:record-button-position {
    bottom: calc(50% - 160px);
  }
}

@media (min-width: 500px) { /* Tailwind's sm breakpoint */
  .sm\\:record-button-position {
    bottom: calc(50% - 16px);
  }
}

@media (min-width: 640px) { /* Tailwind's sm breakpoint */
  .sm\\:record-button-position {
    bottom: calc(43% - 16px);
  }
}





@media (min-width: 640px) { /* Tailwind's sm breakpoint */
  .sm\\:record-button-position {
    bottom: calc(43% - 16px);
  }
}

@media (min-width: 1280px) { /* Tailwind's xl breakpoint */
  .xl\\:record-button-position {
    bottom: calc(30% - 16px);
  }
}

.image-wrapper {
  width: 1080px;
  height: 605px;
}

.image {
  width: 100%;   /* Relative to the wrapper */
  height: 100%;  /* Relative to the wrapper */
  object-fit: cover; /* Ensure the image covers the specified dimensions */
  display: block; /* Ensures block display to avoid inline element issues */
}


// Tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

// App Stylesheets
@import './styles/custom.scss';
//@import './styles/responsive.scss';